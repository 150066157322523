<template>
 <div class="card event-subscribe" :class="link ? 'background-opacity' : ''">
  <div class="img-card-event">
   <img :src="imgSrc" />
  </div>
  <div class="text-card-event">
   <!-- <div class="tag-event">{{ tag }}</div> -->
   <div>
    <div class="date-card-event">{{ date }}</div>
    <div class="title-card-event">{{ title }}</div>
   </div>
   <div class="description-card-event" v-html="description"></div>
  </div>
  <div v-if="link" class="animation-card" @click="redirect(link)">
   <div class="animation-card-content">
    <span>ACESSAR</span>
    <svg
     width="32"
     height="32"
     viewBox="0 0 24 24"
     xmlns="http://www.w3.org/2000/svg"
    >
     <path
      d="M6 8h5v2H6v8h8v-5h2v5a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2Zm10.614-2H12V4h8v8h-2V7.442l-5.336 5.336-1.414-1.414L16.614 6Z"
      fill="#fff"
      fill-rule="evenodd"
     />
    </svg>
   </div>
  </div>
 </div>
</template>

<script>
export default {
 name: "CardEvent",
 props: {
  tag: {
   default: "SEM CATEGORIA",
  },
  date: {
   default: "(sem data definida)",
  },
  title: {
   default: "Titulo do evento",
  },
  imgSrc: {
   default: "seminario.jpg",
  },
  link: {
    default: ''
  },
  description: {
    default: "--",
  },
 },
 methods: {
    redirect(value) {
        if (this.link) {
            window.open(value, '_blank')
        }
    }
 }
};
</script>

<style>
.event-subscribe {
 display: flex;
 align-items: center;
 gap: 50px;
 text-align: left;
 position: relative;
 overflow: hidden;
}
.animation-card {
 position: absolute;
 width: 100%;
 height: 100%;
 transition: all 0.3s ease-in-out;
 cursor: pointer;
 opacity: 0;
 color: #fff;
 font-weight: 600;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
}
.background-opacity, .background-opacity img, .background-opacity .text-card-event {
  transition: all 0.3s ease-in-out;
}
.background-opacity:hover {
  background: rgba(43, 84, 165, 0.6);
}
.background-opacity:hover img, .background-opacity:hover .text-card-event {
 opacity: 0.1;
}
.animation-card:hover {
 /* background: rgba(0, 0, 0, 0.6); */
 opacity: 1;
}
.animation-card-content {
 display: flex;
 align-items: center;
 gap: 10px;
 font-size: 28px;
}
.img-card-event {
 overflow: hidden;
 width: 200px;
 height: 200px;
 margin-left: 40px;
 border-radius: 10px 10px;
 position: relative;
}
.img-card-event img {
 width: 100%;
 position: absolute;
 top: 0;
 left: 0;
}
.text-card-event {
 display: flex;
 flex-direction: column;
 gap: 10px;
}
.text-card-event .tag-event {
 border: solid #555;
 width: fit-content;
 padding: 5px 15px;
 border-radius: 10px;
 font-weight: 600;
 color: #555;
 opacity: 0.9;
 letter-spacing: 4px;
 font-size: 14px;
}
.title-card-event {
 color: #555;
 font-size: 32px;
 font-weight: 600;
}
.date-card-event {
 font-size: 15px;
 opacity: 0.8;
}
.description-card-event {
 font-size: 16px;
 opacity: 0.8;
}
@media screen and (max-width: 981px) {
 .event-subscribe {
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
 }
 .text-card-event {
  align-items: center;
  gap: 20px;
  padding: 0 20px;
 }
 .img-card-event {
  margin: 0;
 }
}
</style>