<template>
 <div class="home">
  <CardPicture :lastPreaching="lastPreaching" />
  <div class="card resume">
   <div class="resumo-cultos">
    <p>CULTOS</p>
    <span> Quinta: <b>19:30</b> </span>
    <span> Sábado:</span>
    <span> Aliança Teen <b>17:00</b> </span>
    <span> Aliança Jovem <b>19:30</b> </span>
    <span> Domingo: <b>9:00 | 17:00 | 19:30</b> </span>
   </div>
   <div class="line"></div>
   <div class="proximo-evento">
    <p>PRÓXIMO EVENTO</p>
    <span>{{ nextEventDate }}</span>
    <b>{{ nextEventTitle }}</b>
   </div>
   <div class="line"></div>
   <Socials />
  </div>
  <div id="events" v-for="event in events" :key="event.id">
    <CardEvent
     :key="event.id"
     :img-src="event.imgSrc"
     :title="event.title"
     :date="event.date"
     :description="event.description"
     :link="event.link"
    />
  </div>

 </div>
</template>

<script>
import CardPicture from "@/components/CardPicture.vue";
import Socials from "@/components/Socials.vue";
import CardEvent from "@/components/CardEvent.vue";
import axios from "axios";

export default {
 name: "HomeVue",
 components: { CardPicture, Socials, CardEvent },
data() {
 return {
  events: [],
  preachings: [],
  nextEventTitle: "",
  nextEventDate: "",
 };
},
mounted() {
    this.getEvents();
    this.getPreachings();
},
 methods: {
  async getEvents() {
   const response = await axios.get("https://orangered-kudu-279432.hostingersite.com/api/posts");
   response.data.map((event) => {
        this.events.push({
        id: event.id,
        imgSrc: "https://juliocesaar.s3.us-east-1.amazonaws.com/cd4475dfbb06d0751b62f223378794331a8863f3058103b502014ba0720cb15b/" + event.banner,
        dateOriginal: event.start_event,
        date: this.formatEventDate(event.start_event, event.end_event),
        title: event.name,
        description: event.description,
        link: event.link
        });
    });
    this.events = this.events.sort((a, b) => new Date(a.dateOriginal) - new Date(b.dateOriginal))
    this.nextEventTitle = ''
    this.nextEventDate = ''
  },
  async getPreachings() {
   const response = await axios.get("https://orangered-kudu-279432.hostingersite.com/api/preachings");
   response.data.map((preaching) => {
        this.preachings.push({
        id: preaching.id,
        title: preaching.name,
        minister: preaching.minister,
        youtube_link: preaching.youtube_link,
        });
    });
  },
  formatEventDate(startDateStr, endDateStr) {
        const months = [
            'janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 
            'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'
        ]

        const startDate = new Date(startDateStr)
        const endDate = new Date(endDateStr)

        const startDay = startDate.getDate()
        const endDay = endDate.getDate()

        const startMonth = months[startDate.getMonth()]
        const endMonth = months[endDate.getMonth()]

        if (startDay === endDay && startMonth === endMonth) {
            return `${startDay} de ${startMonth}`
        } else if (startMonth === endMonth) {
            return `${startDay} a ${endDay} de ${startMonth}`
        } else {
            return `${startDay} de ${startMonth} a ${endDay} de ${endMonth}`
        }
    }
},
watch: {
    events: {
        handler: function (newEvents) {
        if (newEvents.length > 0) {
            this.nextEventTitle = newEvents[0].title;
            this.nextEventDate = newEvents[0].date;
        }
        },
        deep: true,
    },
},
 computed: {
  lastPreaching() {
   return this.preachings[this.preachings.length - 1];
  },
 },
};
</script>

<style>
.home {
 display: flex;
 flex-direction: column;
 gap: 40px;
 align-items: center;
}
.resume {
 color: #666;
 display: flex;
 justify-content: center;
 gap: 80px;
 align-items: center;
}
.resumo-cultos,
.proximo-evento {
 display: flex;
 flex-direction: column;
 gap: 2px;
 align-items: center;
}
#events {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.resumo-cultos p {
    margin-bottom: -2px;
}
.line {
 width: 0.5px;
 height: 70px;
 background: #333;
 opacity: 0.5;
}

@media screen and (max-width: 981px) {
 .resume {
  flex-direction: column;
  gap: 20px;
 }
 .line {
  width: 150px;
  height: 0.5px;
 }
}
</style>